body {
  margin: 0;
  font-family: 'Bergen', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-size: 10pt;
}

@font-face {
  font-family: 'Bergen';
  src: url('./fonts/BergenMono-Regular.woff') format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

